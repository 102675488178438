$apple-music-red-1: #fa233b;
$apple-music-red-2: #fb5c74;
$apple-grey: #a6a6a6;


.apple-music-icon {
  .badge-border {
    fill: $apple-grey;
  }

  .text {
    fill: #ffffff;
    transition: all .25s;
  }

  .red-box {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#apple-gradient);
    transition: all .25s;
  }

  .music-note {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
  }

  .stop-color-1 {
    stop-color: $apple-music-red-1;
  }

  .stop-color-2 {
    stop-color: $apple-music-red-2;
  }

  &:hover {
    .red-box {
      fill: $apple-music-red-2;
    }

    .text {
      fill: $apple-grey;
    }
  }
}
