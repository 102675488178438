.link-items {
	margin: 0;
  padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
  flex-wrap: wrap;

  li {
    list-style: none;

    a {
      font-size: 4.5rem;
      padding: .5rem;
    	color: #ccc;
      display: inline-block;
    	transition: all .5s;
      cursor: pointer;

      .fa {
        display: block;
      }

      &:hover {
      	color: #fff;
      }

      &.instagram:hover {
      	color: #D42F91;
      }

      &.spotify:hover {
      	color: #1DB954;
      }

      &.soundcloud:hover {
      	color: #FF3D00;
      }

      &.youtube:hover {
        color: #FF0000;
      }
    }
  }
}

.spooker {
	font-size: 3rem;

  &.spooker--link {
    transition: all 1.25s;
    color: #777;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      color: #fff;
      transform: scale(1.1);
      font-size: 6rem;
    }
  }
}

.spooker.big {
	font-size: 5rem;
}
