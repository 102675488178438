.feed-list-container {
  max-width: 600px;
  margin: 0 auto;
}

.feed-list {
  margin: 0;

  &__item {
    padding: 1rem;
    list-style: none;
  }
}
