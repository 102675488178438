.rain-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  background: transparent;
  position: absolute;
  z-index: -1;
}

.rain-drop {
  /*
    bloodred: 138,7,7
    greenrain: 13,52,58
  */

  animation: fall 1s linear reverse infinite;
  stroke-dasharray: 50;
  stroke-linecap: round;
  transform: translateZ(0);

  &:nth-child(2n) {
    // animation: fall 1.9s linear reverse infinite;
    animation-duration: 2s;
    // opacity: .7;
    color: rgb(200,200,200);
    animation-delay: .3s;
  }

  &:nth-child(3n) {
    // animation: fall 1.5s linear reverse 2;
    // opacity: .3;
    color: rgb(120,120,120);
  }
}

/* animate the drops*/
@keyframes fall {
  to {
    stroke-dashoffset: 400;
  }
}
