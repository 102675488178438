.skull-container-old {
  background-color: #fff;
  margin: 0 auto;
  width: 70%;
  max-width: 400px;
  overflow: hidden;
}

.skull {
  width: 100%;
  height: auto;
  fill: black;
  // background: white;
  display: block;

  .fill-white {
    fill: #fff;
  }

  circle {
    animation: drop1 2s;

    &:nth-child(2n) {
      animation-duration: 3s;
    }

    &.eye {
      transition: all 0.5s;
      stroke: black;
      stroke-width: 0;

      &:hover {
        stroke-width: 5;
        r: 8%; // fuck you, any browser that cant just see svg props as css prop
        // stroke-width will do for others until they catch up
        // the radius only increases on chrome as of now
      }

      &.left-eye {
        animation-duration: 2.5s;
      }

      &.right-eye {
        animation-fill-mode: forwards;
        transform: translateY(-600px);
        animation-delay: 2s;
      }
    }
  }

  rect {
    animation: rise 2.4s;
    animation-delay: 1s;

    &:nth-child(2n) {
      animation-duration: 1s;
    }

    &:nth-child(3n) {
      animation: drop2 0.5s;
    }

    &:nth-child(8) {
      animation: revealFromSide 2s;
    }

    &.black_bottom {
      transition: 0.5s all;
      animation: slowRise 3s;
    }
  }

  .drop {
    transition: all 0.75s;

    &:hover {
      transform: translateY(-65px);
    }
  }

  .left-eye-brow {
    animation: drop2 2.4s;
  }

  .forehead_crack {
    animation: drop2 5.1s;
  }

  .left_black_bar {
    animation: spin 5s;
  }

  .right-of-face-bar {
    animation: spin 3s;
  }

  .right-head-crack {
    animation: drop1 2.5s;
  }

  .white_drop_left_bottom {
    animation: dripdrop 4s;
    animation-delay: 2s;
  }

  .left-drop {
    animation-fill-mode: forwards;
    transform: translateY(-600px);
    animation: dripdrop 2s;
    animation-delay: 2s;
  }

  .white-rect-bottom {
    animation: rise 3s;
  }

  .top_right_mid_head {
    animation: spin 2s;
  }
}

@keyframes drop1 {
  from {
    transform: translateY(-400px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes drop2 {
  from {
    transform: translateY(-800px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes dripdrop {
  0% {
    height: 0;
    transform: translateY(-700px);
  }
  100% {
    height: auto;
    transform: translateY(0);
  }
}

@keyframes rise {
  from {
    transform: translateY(600px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slowRise {
  0% {
    transform: translateY(600px);
  }
  50% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes revealFromSide {
  from {
    transform: translateX(-700px);
  }
  to {
    transform: translateX(0);
  }
}
