.toggle-button {
  border: none;
  padding: 1rem 1.5rem;
  color: #666;
  background: none;
  cursor: pointer;

  &:hover {
    color: white;
  }
}
