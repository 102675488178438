$link-color: #fff;
$text-color: #fff;
$background-color: #000;

@font-face {
  font-family: 'spookyregular';
  src: url('../fonts/spooky-webfont.woff2') format('woff2'),
    url('../fonts/spooky-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'headhunterregular';
  src: url('../fonts/Headhunter-Regular-webfont.woff') format('woff'),
    url('../fonts/Headhunter-Regular-webfont.ttf') format('truetype'),
    url('../fonts/Headhunter-Regular-webfont.svg#headhunterregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

.spookyregular {
  font-family: 'spookyregular';
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $background-color;
  color: #fff;
  font-size: 100%;
  font-family: sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  text-align: center;
  font-family: 'headhunterregular';
}

a {
  color: $link-color;
  text-decoration: none;
  transition: all 0.5s;

  &:hover {
    color: #888;
  }
}

.container {
  text-align: center;
  padding-bottom: 2rem;
  width: 100%;
  max-width: 100%;
}

.content-container {
  width: 100%;
  z-index: 10;
}

.hide {
  display: none;
}

.visibility--hidden {
  visibility: hidden;
}

.padding-top {
  padding-top: 1rem;
}

.site-footer {
  padding-top: 10rem;
}

.copyright {
  font-size: 0.625rem;
  font-style: italic;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
